.success-message {
  text-align: center;
  margin-top: 40px;
  img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }
  &__header {
    margin: 1px;
    color: rgb(47, 0, 255);
  }

  &__nombre {
    margin: 0px;
    font-size: 28px;
  }
  &__parrafo {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 500;
  }
}

.completar-form {
  &__content {
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
    h2 {
      text-align: center;
    }

    form {
      margin: 20px;
    }
  }
}
