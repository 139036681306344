.uplaod-form {
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    // border: 1px solid rgb(241, 175, 175);

    > .ui.image {
      margin: 0;
      border: 2px dashed blue;
      padding: 5px;
      cursor: pointer;
    }
  }

  form {
    p {
      text-align: center;
      font-size: 20px;
    }
    .field {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
