@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-secondary text-secondary bg-gradient-to-b from-[#F6FAFD] to-[#FFFFFF] bg-no-repeat;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary text-primary;
  }
  a {
    @apply text-secondary font-semibold hover:text-accent-primary_hover transition;
  }
  .btn {
    @apply text-lg h-[48px] px-[30px] rounded-lg transition text-white;
  }
  .btn-primary {
    @apply bg-accent-primary hover:bg-accent-primary_hover;
  }
  .btn-secondary {
    @apply bg-accent-secondary hover:bg-accent-secondary_hover;
  }
  .btn-quaternary {
    @apply bg-white text-base font-bold text-primary drop-shadow-primary hover:bg-accent-primary_hover hover:text-white;
  }
  .h1 {
    @apply text-[40px] xl:text-[80px] font-bold leading-[1.1];
  }
  .h2 {
    @apply text-[40px] font-bold leading-tight;
  }
  .h3 {
    @apply text-[24px];
  }
  .lead {
    @apply text-lg text-secondary font-secondary font-medium leading-relaxed;
  }

  .form-control {
    @apply w-full leading-snug py-2 px-4 rounded text-gray-900 border border-blue-300 bg-gray-200;
  }
  .form-control:hover {
    @apply bg-white;
  }
  .form-control:focus {
    @apply outline-none;
  }
  .Card {
    @apply w-full h-72 flex-none shadow-lg rounded-lg transform transition-all hover:-translate-y-4 hover:shadow-xl md:shadow-lg  md:w-96 md:h-96 lg:w-80 lg:h-80;
  }
}

/* @layer components {
  .header-icon {
    @apply w-6 h-6 cursor-pointer;
  }
  .nav-icon {
    @apply w-6 h-6 cursor-pointer;
  }
  .invest-icon {
    @apply w-6 h-6 stroke-gray-600;
  }
} */
