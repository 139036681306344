.registrogh-form {
  width: 100%;
  &__document {
    // display: inline;
    // align-items: center;
    // justify-content: center;
    // margin-bottom: 20px;
    margin: 25px;
  }
}
