.experiencia-laboral {
  width: 100%;

  &__add {
    position: absolute;
    right: 0;

    > .ui.button {
      margin: 0;
      padding: 0;
    }
  }

  &__campos {
    margin: 0 -0.5em 1em;
  }
}
