.profile-image {
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    > .ui.image {
      margin: 0;
      border: 2px dashed blue;
      padding: 5px;
      cursor: pointer;
    }
  }

  // &__parrafo {
  //   text-align: center;
  //   font-size: 20px;
  // }

  form {
    p {
      text-align: center;
      font-size: 20px;
    }

    .field {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
